import React from 'react';
import {GoogleLogin} from 'react-google-login';

import {refreshTokenSetup} from "../utils/refreshToken";

const clientId = "594487456113-khkjtm3a8ove03o0q0klld24rbcfgoe6.apps.googleusercontent.com";

export default function Login() {

    const handleLoginSuccess = (res) =>{
        console.log("success");
        refreshTokenSetup(res);

        window.location = "/mom";
    }
    const handleLoginFail = (res) =>{
        console.log("fail");
    }
      return(
          <div>
            <GoogleLogin
            clientId={clientId}
            buttonText="Log in with Google"
            onSuccess={handleLoginSuccess}
            onFailure={handleLoginFail}
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
            />
        </div>
      )
}