
import React,{useEffect,useState} from 'react';

export default function GetData(props) {
    const [user, setUser] = useState(null);

    async function fetchUserData(id) {
      const response = await fetch(id);
      const data =await response.json();

        var datas = Object.keys(data).map(function(key) {

        return <strong >{data[key].id} : {data[key].title} <br /></strong> 

       });

      setUser(datas );

    }

    
  
    useEffect(() => {

        // const dInterval = setInterval(() => {
            fetchUserData("https://jsonplaceholder.typicode.com/todos");
        // }, 1000);
      
      }, []);
  
  
      if (!user) {
        return "loading...";
      }
    
      return (
        <details>
          <summary>Get Data from JSON place Holder</summary>
        
          <div className="ud">
              USER ID: {user.id}
          </div> 
          <div className="ud">
              USER TITLE: {user.title}
          </div>
          <div className="ud" style={{height: "420px",overflow: "auto"}}>
              USER COMPLETE: {user}
         </div>

          <br />
         
        </details>
      );
  }