import React from 'react';
import {GoogleLogout} from 'react-google-login';

const clientId = "594487456113-khkjtm3a8ove03o0q0klld24rbcfgoe6.apps.googleusercontent.com";

export default function Logout() {

    const handleLogoutSuccess = (res) =>{
        console.log("logout success");
        //go to home page
        window.location ="/";
    }

      return(
          <div>
            <GoogleLogout
            clientId={clientId}
            buttonText="Logout"
            onLogoutSuccess={handleLogoutSuccess}
            style={{ float: 'right' }}
             ></GoogleLogout>
        </div>
      )
}