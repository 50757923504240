import React,{useEffect} from 'react'
import ReactEcharts from 'echarts-for-react'
import MaterialTable from "material-table";
import GetData from "../components/GetData";
import Logout from '../components/Logout';
import $ from "jquery";

import {sumRectangle,getUmfang} from '../utils/rectangle'; 
export default function Momentanwerte () {
    
    let data  = [
        {
        nr: "1",
        name: "Hauptzähler",
        value: 17,
        unit: "kW"
        
        },
        {
            nr: "2",
            name: "boiler",
            value: 17,
            unit: "kW"
            
            },
            {
                nr: "3",
                name: "Hauptzähler2",
                value: 176,
                unit: "kW"
                
                },
                {
                    nr: "4",
                    name: "Hauptzähler3",
                    value: 117,
                    unit: "kW"
                    
                    }
    ]
    const sum =   sumRectangle(22,55);
    const umfang = getUmfang(22,55);
    const data2 = {};
    data2.values =data.map((obj) =>{ return obj.value });
    data2.name = data.map((option) =>{return  option.name });

    const getUserDataID =()=>{
      
        alert( "nr is:" + data[0].nr);
    }

    //add jquery functions here datatables, old events
    useEffect(() => {

        $("button").on("click",function(){
            alert("yesa");
        });


    });
    return (
<div className="header">
    
        <Logout></Logout>
        <div className="headerTitle">
            <h1>Momentanwerte</h1></div>
        <div className="tableMom">
            <h2>Tabelle</h2>
            <div className="tableContainer" style={{height:500}}>

            <MaterialTable
                columns={[
                    { title: "Nr", field: "nr" },
                    { title: "Name", field: "name" },
                    { title: "Wert", field: "value", }
                    , { title: "Einheit", field: "unit", }
                    
                ]}
                options={{
                    exportButton: true
                  }}
                data={data}
                title="Momentanwerte"
             />

        <div className="chartmom">
            <h2>Grafik Momentanwerte</h2>
        
            <div className="chartContainer" style={{height:500}}>

           
            <ReactEcharts
                option={{
                xAxis: {
                    type: 'category',
                    data: data2.name
                },
                yAxis: {
                    type: 'value'
                },
                series: [{ 
                    data: data2.values,
                    type: 'bar'
                }]
                }}
            />

            </div>
        
        </div>
        <div className="dataContainer" style={{height:500}}>

            <GetData/>
            <button className="myBtn y" onClick={ getUserDataID}> Click to get User Data</button>
        </div>

        <div id="sumValue1">Fläche: {sum} und Umfang ist {umfang}</div>
           
            
          
            </div>
        </div>
       
</div>
) }