

export const sumRectangle = (a,b) =>{

  var sum = a * b;
 
  return sum;

};

export const getUmfang = (a,b) =>{

    var umfang = a +a +b +b;
    return umfang;
}