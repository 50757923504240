import React,{useState} from 'react'

import Login from '../components/Login'
export default function Home () {
    
    const [count,setCount] = useState(0);

    const addCount =()=>{
        setCount(count+1);
    }
    return (<div>
    
    <h1>HOME MYASKI </h1>
    
    <h2>BY PETER MAYRHOFER</h2>
    
    <img src="https://www.aski-energy.com/content/uploads/2021/02/system-01.svg" alt="haus" height="200px" />


    <div className="col-md-12 " style={{margin:30}}>
        <button className="myBtn" onClick={addCount} >LOGIN TO PARADISE</button>
    </div>

<Login></Login>
   
    {count !==0 ? <p>You clicked {count} times</p>  : <p></p>}
        
 

    
</div>) }